import React from "react";
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";

import "./style.css";
import Logo from "../images/logo-fiduciaria-borderless.svg";

export default function Header(props) {
  const location = useLocation();
  return (
    <React.Fragment>
      <header className='App-header'>
        <div className='logo-holder'>
          <Link
            to={location.pathname.split("/")[1] + `/`}
            className='site-title'
            title='iduciaria - SAM & Partners'
            style={{
              display: "inline-block",
            }}
          >
            <img
              className='site-logo'
              width='300'
              height='80'
              src={Logo}
              alt='Fiduciaria - SAM & Partners'
              title='Fiduciaria - SAM & Partners'
            />
          </Link>
        </div>
        <div className='lang-holder'>
          <CustomLink
            to='/de/'
            title='German'
            style={{
              display: "inline-block",
            }}
            className={
              location.pathname.split("/")[1] === "de"
                ? "site-link-active"
                : "site-link"
            }
          >
            DE
          </CustomLink>
          <CustomLink
            to='/en/'
            title='English'
            style={{
              display: "inline-block",
            }}
            className={
              location.pathname.split("/")[1] === "en"
                ? "site-link-active"
                : "site-link"
            }
          >
            EN
          </CustomLink>
          <CustomLink
            to='/it/'
            title='Italian'
            style={{
              display: "inline-block",
            }}
            className={
              location.pathname.split("/")[1] === "it"
                ? "site-link-active"
                : "site-link"
            }
          >
            IT
          </CustomLink>

          {/* <Link
            to='/de/'
            title='German'
            style={{
              display: "inline-block",
            }}
            className={
              location.pathname === "/de/" ? "site-link-active" : "site-link"
            }
          >
            DE
          </Link>
          <Link
            to='/en/'
            title='English'
            style={{
              display: "inline-block",
            }}
            className={
              location.pathname === "/en/" ? "site-link-active" : "site-link"
            }
          >
            EN
          </Link>
          <Link
            to='/it/'
            title='Italian'
            style={{
              display: "inline-block",
            }}
            className={
              location.pathname === "/it/" ? "site-link-active" : "site-link"
            }
          >
            IT
          </Link> */}
        </div>
      </header>
    </React.Fragment>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
