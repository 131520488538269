import React, { Suspense, lazy, globalStore } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { motion, useScroll } from "framer-motion";

import "./App.css";
import Header from "./header";

const Home = lazy(() => import("./pages/home"));
const Impressum = lazy(() => import("./pages/Impressum"));

function App() {
  const { scrollYProgress } = useScroll();

  return (
    <React.Fragment>
      <motion.div
        className='progress-bar'
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <div className='App'>
        <Suspense fallback={<div className='loading loading-lg'></div>}>
          <Routes>
            <Route path='/' element={<Navigate to='/de/' />} />
            <Route path='/:lang/' element={<Home />} />
            <Route path='/:lang/:Impressum' element={<Impressum />} />
          </Routes>
        </Suspense>
      </div>
    </React.Fragment>
  );
}

export default App;
